import React, { useState, useCallback } from 'react'
import {
  TextInput,
  SelectInput,
  NumberInput,
  Button,
  useNotify
} from 'react-admin'
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import enLocale from 'date-fns/locale/en-US'
import { DateInput, TimeInput } from '@sklinet/react-admin-date-inputs'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { useFormState, useForm } from 'react-final-form';

import { useFirebase } from '../core/ProvideFirebase'

const DAYS = [
  {id: 'Sun', name: 'Sun'},
  {id: 'Mon', name: 'Mon'},
  {id: 'Tue', name: 'Tue'},
  {id: 'Wed', name: 'Wed'},
  {id: 'Thu', name: 'Thu'},
  {id: 'Fri', name: 'Fri'},
  {id: 'Sat', name: 'Sat'},
]

const EditPresentationModal = ({ eventPath, record, onClose }) => {
  const { values } = useFormState({subscription: { values: true }})
  const { firestore } = useFirebase()
  const notify = useNotify()

  const handleSubmit = useCallback(() => {
    const {
      presEdit: {
        [record.id]: newRecord
      }
    } = values
    const rRef = firestore.collection(`events/${eventPath}/presentations`).doc(record.id)
    rRef.update(newRecord).then(() => {
      notify(`Presentation successfully updated`, { type: 'success' })
      onClose()
    })
  }, [values, firestore, record])

  const convertToRegularTime = (val, name) => {
    const hours = parseInt(val.substring(0, 2), 10)
    const minutes = parseInt(val.substring(2, 4), 10)
    const d = new Date()
    d.setUTCHours(hours)
    d.setMinutes(minutes)
    return d
    // return val
    const newVal = `${val.substring(0, 2)}:${val.substring(2, 4)}`
    console.log('cTRT', newVal)
    return newVal
  }
  const convertToMilitaryTime = (val, name) => {
    console.log('Hello!', val, name)
    const vDate = new Date(val)
    const hours = vDate.getUTCHours()
    const minutes = vDate.getMinutes()
    console.log('H', hours, minutes)
    const newVal = (hours * 100 + minutes).toString().padStart(4, '0')
    console.log('Val is ', val, newVal)
    return newVal
    // return `${val.get}`
  }
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Edit Presentation
        <IconButton
          aria-label="close"
          onClick={onClose}
          styles={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextInput source={`presEdit.${record.id}.presentationName`} label="Presentation Name" fullWidth />
          <TextInput source={`presEdit.${record.id}.sessionName`} label="Session Name" fullWidth />
          <TextInput source={`presEdit.${record.id}.roomName`} label="Room Name" fullWidth />
          <DateInput source={`presEdit.${record.id}.presentationDate`} label="Presentation Date" providerOptions={{ utils: MomentUtils, locale: 'en-US' }} />
          <TimeInput source={`presEdit.${record.id}.startTime`} label="Start Time" providerOptions={{ utils: MomentUtils, locale: 'en-US' }} timeZone="UTC" format={convertToRegularTime} parse={convertToMilitaryTime} />
          <SelectInput source={`presEdit.${record.id}.dayName`} choices={DAYS} label="Day Name" fullWidth />
          <TextInput source={`presEdit.${record.id}.presenter.lastName`} label="Presenter Last Name" fullWidth />
          <TextInput source={`presEdit.${record.id}.presenter.firstName`} label="Presenter First Name" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button label="Submit" onClick={handleSubmit}></Button>
        </DialogActions>
      </DialogTitle>
    </Dialog>
  )
}

export const EditPresentationButtonField = ({ eventPath, record }) => {
  const [isEditing, setIsEditing] = useState(false)
  const { change } = useForm()

  const openModal = () => {
    change(`presEdit.${record.id}`, record)
    setIsEditing(true)
  }
  const onClose = () => setIsEditing(false)
  
  if (isEditing) {
    return (<EditPresentationModal eventPath={eventPath} record={record} onClose={onClose} />)
  }
  return (
    <Button
      onClick={openModal}
      label="Edit"
    >
      <EditIcon />
    </Button>
  )
}
import { useRef, useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import OperationsErrorReporting from 'stackdriver-errors-js'
import { useSettings } from '../components/core/ProvideSettings'

const ErrorBlock = ({ error }) => {
  return (
    <div>There was an error. Please contact Michael (michael@jackofallcode.net). {error.toString()}</div>
  )
}

export const OperationsErrorHandler = ({ children }) => {
  const { FIREBASE_CFG } = useSettings()
  const errorHandlerRef = useRef()
  useEffect(() => {
    const theHandler = new OperationsErrorReporting()
    theHandler.start({
      key: FIREBASE_CFG.apiKey,
      projectId: FIREBASE_CFG.projectId
    })
    errorHandlerRef.current = theHandler
  }, [FIREBASE_CFG])
  const handleError = useCallback((error, info) => {
    console.log('Received error: ', error)
    if (!errorHandlerRef.current) { return }
    errorHandlerRef.current.report(error)
  }, [errorHandlerRef.current])
  return (
    <ErrorBoundary onError={handleError} FallbackComponent={ErrorBlock}>
      {children}
    </ErrorBoundary>
  )
}

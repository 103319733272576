import React from 'react'
import { ProvideEventData, useEventData } from './EventContext'
import { Routes, Route } from 'react-router-dom'
import { EventUpload } from './EventUpload'
import { EventShow } from './EventShow'
import { EventLayout } from './EventLayout'

const EventRoutes = () => {
  const { disable_uploads } = useEventData()
  console.log('DU: ', disable_uploads)
  return (
    <Routes>
      <Route path="*" element={<EventLayout />}>
        {disable_uploads ? null : (<Route path="upload" element={<EventUpload />} />)}
        <Route path="*" element={<EventShow />} />
      </Route>
    </Routes>
  )
}

export const EventDirector = () => {
  return (
    <ProvideEventData>
      <EventRoutes />
    </ProvideEventData>
  )
}
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useEventData } from './EventContext'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

const MISSING_DESCRIPTION = 'No description provided.'
const UPLOADS_DISABLED = 'Uploads are currently not being accepted.'

const EventShowUploadLinkOrReason = () => {
  const { disable_uploads = false, disable_reason = UPLOADS_DISABLED } = useEventData()

  if (disable_uploads) {
    return (
      <p>{disable_reason}</p>
    )
  }
  return (
    <Link to="upload">
      <Button variant="contained">
        Upload Presentation Files
      </Button>
    </Link>
  )
}

export const EventShow = () => {
  const { description = MISSING_DESCRIPTION } = useEventData()
  return (
    <>
      <ReactMarkdown children={description} />
      <EventShowUploadLinkOrReason />
    </>
  )
}
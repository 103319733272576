import React, { useState } from 'react'
import {
  Confirm,
  Button
} from 'react-admin'


export const ConfirmButtonField = (props) => {
  const {
    record,
    icon,
    label,
    title = null,
    confirmText = 'Are you sure?',
    onConfirm
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm(record)
  }
  return (
    <>
      <Button label={label} onClick={() => setIsOpen(true)} >{icon}</Button>
      <Confirm
        isOpen={isOpen}
        title={title ? title : label}
        content={confirmText}
        onConfirm={handleConfirm}
        onClose={() => setIsOpen(false)}
      />
    </>
  )

};
import './App.css';
import { LoginModal } from './components/core/LoginModal';
import { ProvideAuth } from './components/core/ProvideAuth';
import { ProvideFirebase } from './components/core/ProvideFirebase';
import { ProvideSettings } from './components/core/ProvideSettings'
import { Wrapper } from './components/layout/Wrapper'
import { OperationsErrorHandler } from './utils/OperationsErrorHandler'


function App() {
  return (
    <ProvideSettings>
      <OperationsErrorHandler>
        <ProvideFirebase>
          <ProvideAuth>
            <Wrapper />
            <LoginModal />
          </ProvideAuth>
        </ProvideFirebase>
      </OperationsErrorHandler>
    </ProvideSettings>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from 'react'
import { Button, FileInput, NumberInput, SelectInput } from 'react-admin'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import isString from 'lodash/isString'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {ExcelRenderer} from 'react-excel-renderer';
import { useFormState } from 'react-final-form';
import { useFirebase } from '../core/ProvideFirebase'

const ExcelTable = ({data, columns}) => {
  console.log('Columns', columns)
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            {columns.map(c => <th key={c.hasOwnProperty('key') ? c.key : 'UNK'}>{c.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((r, idx) => (
            <tr key={`r${idx}`}>
              <th>{idx + 1}</th>
              {columns.map(c => <td key={`r${idx}-c${c.hasOwnProperty('key') ? c.key : 'UNK'}`}>{r[c.key]}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const requiredFields = ['dataStartsOn', 'roomNameCol', 'dateNameCol', 'timeCol', 'lastNameCol', 'sessionNameCol', 'presentationNameCol']

export const getJsDateFromExcel = excelDate => {
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
};

const standardizeDate = (datish) => {
  if (isFinite(datish)) {
    const formattedDate = getJsDateFromExcel(datish)
    return formattedDate
  }
  const parsed = Date.parse(datish)
  if (isNaN(parsed)) { return (new Date()) }
  const theDate = new Date(parsed)
  return theDate
}
const standardizeDay = (datish, input = '') => {
  if (isFinite(input)) {
    const formattedDate = getJsDateFromExcel(input)
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][formattedDate.getDay()]
  }
  const parsed = Date.parse(datish)
  if (!isNaN(parsed)) { 
    const theDate = new Date(parsed)
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][theDate.getDay()]
  }

  const lowered = new RegExp('^' + (input || '').toString().toLowerCase())
  if ('sunday'.match(lowered)) { return 'Sun' }
  if ('monday'.match(lowered)) { return 'Mon' }
  if ('tuesday'.match(lowered)) { return 'Tue' }
  if ('wednesday'.match(lowered)) { return 'Wed' }
  if ('thursday'.match(lowered)) { return 'Thu' }
  if ('friday'.match(lowered)) { return 'Fri' }
  if ('saturday'.match(lowered)) { return 'Sat' }
  const parsed2 = Date.parse(input)
  if (isNaN(parsed2)) { return 'Fri' }
  const theDate = new Date(parsed2)
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][theDate.getDay()]
}
const parseTimeAsMilitary = (datish = '') => {
  if (datish === '') { return '0800' }
  if (isFinite(datish)) {
    if (isString(datish) && datish.match('^[0-9]{3,4}$')) { return datish.padStart(4, '0') }
    if (isString(datish) && datish.match('^[0-9]{1,2}:[0-9]{2}$')) { return datish.replace(':', '').padStart(4, '0') }

    const formattedDate = getJsDateFromExcel(datish)
    return (formattedDate.getUTCHours() * 100 + formattedDate.getMinutes()).toString().padStart(4, '0')    
  }

  const complexMatcher = datish.match('^([0-9]{1,2}):([0-9]{2}) *(a|p)\.*m*\.*$')
  if (complexMatcher) { 
    const hour = parseInt(complexMatcher[1], 10)
    if (complexMatcher[3] === 'p') {
      hour += 12
    }
    return (hour * 100 + parseInt(complexMatcher[2], 10)).toString().padStart(4, '0')
  }
  return '0800'
}

const ExcelPreview = ({onClose, slug, eventPath, record: { rawFile } = {}}) => {
  const [localData, setLocalData] = useState(null)
  const [readyForUpload, setReadyForUpload] = useState(false)
  const { firestore } = useFirebase()
  const { values } = useFormState({subscription: { values: true }})

  useEffect(() => {
    ExcelRenderer(rawFile, (err, data) => {console.log('Received success?'); setLocalData(data)})
  }, [rawFile])
  useEffect(() => {
    const {
      uploadish: {
        [slug]: subset = {}
      } = {}
    } = values
    console.log('subset', subset)
    setReadyForUpload(requiredFields.every(f => subset.hasOwnProperty(f) && subset[f] !== ''))
  }, [values, slug])
  const processData = () => {
    const {
      uploadish: {
        [slug]: subset = {}
      } = {}
    } = values
    const assembler = []
    for (var i = subset.dataStartsOn - 1; i < localData.rows.length; i++) {
      const thisRow = localData.rows[i]
      const innerAssembler = {
        roomName: thisRow[subset.roomNameCol],
        presentationDate: standardizeDate(thisRow[subset.dateNameCol]),
        dayName: standardizeDay(thisRow[subset.dateNameCol], thisRow[subset.dayNameCol]),
        startTime: parseTimeAsMilitary(thisRow[subset.timeCol]),
        presenter: {
          lastName: thisRow[subset.lastNameCol],
          ...(subset.firstNameCol && {firstName: thisRow[subset.firstNameCol]}),
        },
        sessionName: thisRow[subset.sessionNameCol],
        presentationName: thisRow[subset.presentationNameCol],
        sortOrder: (subset.sortOrderCol ? thisRow[subset.sortOrderCol] : 1)
      }
      assembler.push(innerAssembler)
    }
    console.log('assembler', assembler)
    const promises = []
    while (assembler.length > 0) {
      const batch = firestore.batch()
      var i = 0
      while (i < 300 && assembler.length > 0) {
        const nRef = firestore.collection(`events/${eventPath}/presentations`).doc()
        batch.set(nRef, assembler.pop())
      }
      promises.push(batch.commit())
    }
    Promise.all(promises).then(onClose)
  }
  if (!localData) {
    return null
  }

  // Room, day, time, last name, first name, session name, presentation name
  return (
    <>
      <NumberInput source={`uploadish.${slug}.dataStartsOn`} label="Row data starts on?" />
      <SelectInput source={`uploadish.${slug}.roomNameCol`} label="Column of Room Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.dayNameCol`} label="Column of Day Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.dateNameCol`} label="Column of Date?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.timeCol`} label="Column of Start Time?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.lastNameCol`} label="Column of Presenter's Last Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.firstNameCol`} allowEmpty label="Column of Presenter's First Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.sessionNameCol`} label="Column of Session Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.presentationNameCol`} label="Column of Presentation Name?" choices={localData.cols} optionValue="key" />
      <SelectInput source={`uploadish.${slug}.sortOrderCol`} allowEmpty label="Column of Sort Order?" choices={localData.cols} optionValue="key" />
      <Button label="Process Excel" onClick={processData} disabled={!readyForUpload} />
      <ExcelTable
        data={localData.rows}
        columns={[{name: ''}].concat(localData.cols)}
      />
    </>
  )
}

const PseudoShredderModal = ({slug, eventPath, modalOpen, setModalOpen}) => {
  const onClose = useCallback(() => setModalOpen(false), [setModalOpen])
  return (
    <Dialog
      fullScreen
      open={modalOpen}
      onClose={onClose}
    >
      <DialogTitle>
        Upload Presentation File
        <IconButton
          aria-label="close"
          onClick={onClose}
          styles={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileInput source={`uploads.${slug}`} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'>
          <ExcelPreview slug={slug} eventPath={eventPath} onClose={onClose} />
        </FileInput>
      </DialogContent>
    </Dialog>
  )
}

export const PseudoShredder = ({eventPath}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [slug] = useState((new Date()).getTime())

  return (
    <>
      <PseudoShredderModal slug={slug} eventPath={eventPath} modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Button onClick={() => setModalOpen(true)} label="Upload Presentations" />
    </>
  )
}


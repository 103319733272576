import React, { useState, useCallback } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { arrayUnion } from 'firebase/firestore'
import { Loading } from '../core/Loading'
import { useFirebase } from '../core/ProvideFirebase'
import { useEventData } from './EventContext'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import CRC32 from 'crc-32';
import { toast } from 'react-toastify';
import isObject from 'lodash/isObject'

import { displayPresenterName } from '../../utils/displayPresenterName'

const UploadForPresentation = ({presentation, setCurrentPresentation}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false)
  const { firebaseStorage, firestore } = useFirebase()
  const { eventPath } = useEventData()

  const performUpload = () => {
    setUploading(true)
    console.log('Selfile', selectedFile)
    const sRef = firebaseStorage.ref()
    const modifiedName = selectedFile.name.replace(new RegExp('\.[^.]+$'), '-' + (new Date()).toISOString().replace(new RegExp('\\..+'), '') + '$&')
    const fileRef = sRef.child(`events/${eventPath}/uploads/staging/${modifiedName}`)
    const magicString = [
      presentation.dayName,
      presentation.roomName,
      presentation.startTime,
      presentation.presenter.lastName,
      presentation.sortOrder
    ].join('|')
    const metadata = {
      customMetadata: {
        day: presentation.dayName,
        room: presentation.roomName,
        startTime: presentation.startTime,
        lastName: presentation.presenter.lastName,
        ...(presentation.presenter.lastName && {lastName: presentation.presenter.lastName}),
        sortOrder: presentation.sortOrder,
        token: CRC32.str(magicString)
      }
    };
    console.log(metadata)
    fileRef.put(selectedFile, metadata).then(s => { 
      const dRef = firestore.collection(`events/${eventPath}/presentations`).doc(presentation.id)
      return dRef.update({
        files: arrayUnion(modifiedName)
      })
    }).then(() => {
      toast(`File ${selectedFile.name} Uploaded!`)
      setCurrentPresentation(null); 
      setUploading(false)
    }).catch(err => {console.log('Error!', err)})
  }

  return (
    <Dialog
      fullWidth
      open={!!presentation}
      onClose={() => { setUploading(false); setCurrentPresentation(null) }}
    >
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <input type="file" onChange={(e) => {setSelectedFile(e.target.files[0]); setUploading(false)} } />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={performUpload} disabled={!selectedFile || uploading}>{uploading ? 'Upload in progress, please wait...' : 'Upload'}</Button>
      </DialogActions>
    </Dialog>
  )
}

const formatToAMPM = timeInMil => {
  const intTime = parseInt(timeInMil, 10)
  const hour = Math.floor(intTime / 100)
  const minutes = (intTime % 100).toString().padStart(2, '0')
  if (hour > 12) {
    return `${hour - 12}:${minutes} p.m.`
  }
  return `${hour}:${minutes} a.m.`
}

const fetchDateFromPresentation = presentation => {
  if (isObject(presentation.presentationDate)) {
    return new Date(presentation.presentationDate.seconds * 1000)
  } else {
    return presentation.presentationDate
  }
}

const formatFullDateInfo = presentation => {
  const theDate = fetchDateFromPresentation(presentation)
  const intTime = parseInt(presentation.startTime, 10)
  console.log(intTime)
  const hour = Math.floor(intTime / 100)
  const minutes = (intTime % 100).toString().padStart(2, '0')
  theDate.setHours(hour)
  theDate.setMinutes(minutes)
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return theDate.toLocaleString("en-US", options)
}

const _PresentationDisplay = ({ classes, setCurrentPresentation, presentation }) => {
  return (
    <Grid item xs={12} className={classes.row}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Grid container>
            <Grid item xs={12} sm={8}>{displayPresenterName(presentation)}</Grid>
            <Grid item xs={12} sm={4}>{formatFullDateInfo(presentation)}</Grid>
            <Grid item xs={12} sm={8}>{presentation.sessionName}</Grid>
            <Grid item xs={12} sm={4}>{presentation.roomName}</Grid>
            <Grid item xs={12}>{presentation.presentationName}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" onClick={() => setCurrentPresentation(presentation)}>Upload File</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  row: {
    marginBottom: '20px',
    paddingBottom: '10px',
    borderBottom: '1px solid black'
  }
})

export const PresentationDisplay = withStyles(styles)(_PresentationDisplay)

const presentationMatchesFilter = (filterText, presentation) => {
  if (!filterText || filterText === '') { return true }
  const cleanedFilter = new RegExp(filterText.replace(' ', '.*'), 'i')
  const {
    presentationName = '',
    presenter: {
      firstName = '',
      lastName = ''
    } = {}
  } = presentation
  if (presentationName !== '' && presentationName.match(cleanedFilter)) { return true }
  if (`${firstName} ${lastName}`.match(cleanedFilter)) { return true }
  if (`${lastName}, ${firstName}`.match(cleanedFilter)) { return true }
  return false
}

export const EventUpload = () => {
  const { firestore } = useFirebase()
  const { eventPath } = useEventData()
  const [currentPresentation, setCurrentPresentation] = useState(null)
  const [filter, setFilter] = useState('')
  const [presentations, loading, error] = useCollectionDataOnce(
    firestore.collection(`events/${eventPath}/presentations`), 
    {idField: 'id'}
  )

  if (loading) {
    return <Loading />
  }

  return (
    <div style={{marginRight: '10px', paddingRight: '10px'}}>
      <TextField fullWidth label="Filter" variant="outlined" onChange={e => setFilter(e.target.value)} value={filter} style={{marginBottom: '20px'}} />
      <UploadForPresentation presentation={currentPresentation} setCurrentPresentation={setCurrentPresentation} />
      <Grid container>
        {presentations.map(presentation => presentationMatchesFilter(filter, presentation) && <PresentationDisplay key={presentation.id} presentation={presentation} setCurrentPresentation={setCurrentPresentation} />)}
      </Grid>
    </div>
  )

  // "#{presenter_last_name}, #{presenter_first_name} - #{name}, #{location} @ #{start_time.strftime('%F %l:%M %P')}"

}
import React, { useCallback, useState } from 'react'
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextField,
  TextInput,
  EditButton,
  Edit,
  FileInput,
  FileField,
  BooleanInput,
  BooleanField,
  FormDataConsumer,
  Loading,
  useList,
  ListContextProvider,
  FunctionField,
  SimpleFormIterator,
  Button,
  DateField,
  TopToolbar,
  DeleteButton,
  ArrayInput,
  Toolbar,
  SaveButton,
  useNotify,
  Confirm
} from 'react-admin'
import MarkdownInput from 'ra-input-markdown';
import { ColorInput } from 'react-admin-color-input';
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmButtonField } from '../../utils/ConfirmButtonField'
import DeleteIcon from '@material-ui/icons/Delete';

import { useFirebase } from '../core/ProvideFirebase';
import { PseudoShredder } from './PseudoShredder'
import { displayPresenterName } from '../../utils/displayPresenterName'
import { useSettings } from '../core/ProvideSettings'
import { EditPresentationButtonField } from './EditPresentation'

export const EventsList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="fullName" />
        <BooleanField source="public" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const EventsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" />
        <TextInput source="fullName" />
      </SimpleForm>
    </Create>
  )
}

// TODO: Implement in-line editing via a popup modal (RA should have a Modal component already)
// TODO: Finish the function that will delete all presentations, etc... when an event is deleted.

const PresentationFile = ({eventPath, file}) => {
  const { firebaseStorage } = useFirebase()
  const [downloadUrl, loading, error] = useDownloadURL(firebaseStorage.ref(`events/${eventPath}/uploads/staging/${file}`));
  console.log('Received: ', file, downloadUrl)
  return (
    <div>
      <a href={downloadUrl}>{file}</a>
    </div>
  )
}

const PresentationFileDisplays = ({files, eventPath}) => {
  return (
    <div>
      {files.map(f => <PresentationFile eventPath={eventPath} file={f} key={f} />)}
    </div>
  )
}

const DeleteAllPresentations = ({ data, eventPath }) => {
  const { firestore } = useFirebase()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const notify = useNotify()

  const handleConfirm = () => {
    const promises = []
    while (data.length > 0) {
      const batch = firestore.batch()
      var i = 0
      while (i < 300 && data.length > 0) {
        const current = data.pop(0)
        const nRef = firestore.collection(`events/${eventPath}/presentations`).doc(current.id)
        batch.delete(nRef)
      }
      promises.push(batch.commit())
    }
    Promise.all(promises).then(() => {
      setConfirmOpen(false)
      notify(`All presentations for ${eventPath} deleted.`, {type: 'success'})
    })

  }

  return (
    <>
      <Button label="Delete All Presentations" onClick={() => setConfirmOpen(true)} ><DeleteIcon /></Button>
      <Confirm
        isOpen={confirmOpen}
        title="Delete All Presentations"
        content="Are you sure you want to delete all presentations? This is non-reversible!"
        onConfirm={handleConfirm}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  )
}

const PresentationRender = ({data, eventPath}) => {
  const { firestore } = useFirebase()
  const notify = useNotify()
  const listContext = useList({
    data,
    ids: data.map(d => d.id),
    loaded: true
  })
  const deletePresentation = (record) => {
    const dRef = firestore.collection(`events/${eventPath}/presentations`).doc(record.id)
    dRef.delete().then(() => {
      notify(`Presentation "${record.presentationName}" Deleted`, {type: 'success'})
    })
  }
  const recordSlug = record => [
      record.dayName,
      record.roomName,
      record.startTime,
      record.presenter.lastName
    ].join('.')

  return (
    <>
      <DeleteAllPresentations data={data} eventPath={eventPath} />
      <ListContextProvider value={listContext}>
        <Datagrid>
          <FunctionField source="presenter.lastName" render={displayPresenterName} label="Presenter" />
          <TextField source="presentationName" />
          <TextField source="sessionName" />
          <DateField source="presentationDate" />
          <TextField source="dayName" />
          <TextField source="roomName" />
          <TextField source="startTime" />
          <FunctionField label="Folder Slug" render={recordSlug} />
          <FunctionField label="Files Uploaded" render={record => record.files ? <PresentationFileDisplays eventPath={eventPath} files={record.files} /> : 'None'} />
          <EditPresentationButtonField eventPath={eventPath} label="Edit" />
          <ConfirmButtonField icon={<DeleteIcon />} label="Delete" onConfirm={deletePresentation} />
        </Datagrid>
      </ListContextProvider>
    </>
  )
}

const PresentationBlock = ({eventPath}) => {
  const { firestore } = useFirebase()
  const transformer = useCallback(({ presentationDate, ...rest}) => ({presentationDate: presentationDate.toDate(), ...rest}))
  const [data, loading, error] = useCollectionData(
    firestore.collection(`events/${eventPath}/presentations`), 
    {idField: 'id', transform: transformer}
  )
  
  if (loading) {
    return <Loading />
  }
  if (error) {
    return (
      <div>
        Something went wrong: {error.toString()}
      </div>
    )
  }
  return (
    <div style={{maxWidth: '90%', overflowX: 'scroll'}}>
      <PresentationRender data={data} eventPath={eventPath} />
    </div>
  )
}

const DownloadZip = ({ eventPath, id }) => {
  const { firebaseStorage } = useFirebase()
  const [downloadUrl, loading, error] = useDownloadURL(firebaseStorage.ref(`events/${eventPath}/zips/${id}.zip`));
  if (loading) {
    return <Loading />
  }
  return <a href={downloadUrl}>Download</a>
}

const ZipRender = ({ data, eventPath }) => {
  const listContext = useList({
    data,
    ids: data.map(d => d.id),
    loaded: true
  })
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="createdAt" />
        <FunctionField source="ready" render={record => record.ready && <DownloadZip eventPath={eventPath} id={record.id} />} />
      </Datagrid>
    </ListContextProvider>
  )
}

const ZipBlocks = ({ eventPath }) => {
  const { firestore } = useFirebase()
  const { FIREBASE_CFG } = useSettings()
  const [data, loading, error] = useCollectionData(
    firestore.collection(`events/${eventPath}/zips`), 
    {idField: 'id'}
  )
  const createZip = () => {
    const zRef = firestore.collection(`events/${eventPath}/zips`).doc()
    zRef.set({
      createdAt: (new Date()).toISOString(),
      storageBucket: FIREBASE_CFG.storageBucket
    })
  }
  
  if (loading) {
    return <Loading />
  }
  if (error) {
    return (
      <div>
        Something went wrong: {error.toString()}
      </div>
    )
  }
  return (
    <>
      <Button onClick={createZip} label="Generate Zip File" />
      <ZipRender data={data} eventPath={eventPath} />
    </>
  )
}

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const CustomToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton undoable={false} label="Delete Event" />
  </Toolbar>
);

export const EventsEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="summary">
          <TextInput disabled source="id" />
          <TextInput source="fullName" />
          <MarkdownInput source="description" />
          <FileInput source="images.banner">
            <FileField source="src" title="title" />
          </FileInput>
          <ColorInput source="bannerColor" />
          <BooleanInput source="hideHeader" />
          <ColorInput source="headerBackgroundColor" />
          <ColorInput source="headerFontColor" />
          <BooleanInput source="public" />
          <BooleanInput source="disable_uploads" />
          <TextInput source="disable_reason" />
          <ArrayInput source="notifications">
            <SimpleFormIterator>
              <TextInput source="email" />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source="enable_notifications" />
        </FormTab>
        <FormTab label="presentations">
          <FormDataConsumer>
            {({record}) => {
              return (
                <>
                  <PseudoShredder eventPath={record.id} />
                  <PresentationBlock eventPath={record.id} />
                </>
              )
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="zips">
          <FormDataConsumer>
            {({record}) => {
              return (
                <>
                  <ZipBlocks eventPath={record.id} />
                </>
              )
            }}
          </FormDataConsumer>

        </FormTab>
      </TabbedForm>
    </Edit>
  )
}